@import "variables";
@import "txt";
@import "animations";
@import "css-variables";
@import "controls/markdown";
@import "controls/link";
@import "controls/icons";
@import "controls/checkbox";
@import "controls/form";
@import "controls/input";
@import "controls/button";
@import "controls/portal";
@import "controls/ng-select";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}

.ui-tooltip {
  position: fixed;
  color: #fff;
  top: 100%;
  left: 50%;
  //transform: translateX(-50%);
  z-index: 9999;
  margin-top: 10px;
  animation-duration: 0.05s;
  transform-origin: top left;

  @include txt-12;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--Porcelain, #ECF0F1);

  white-space: pre-line;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
/*
* {
  box-sizing: border-box;
}*/

iframe:not([src]):empty {
  display: none;
}

h1 {
  font-family: $font;
  font-size: 60px;
  margin: 0;
  padding: 0;
  color: var(--Black, #151414);
}

h2 {
  color: var(--Black, #151414);
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
  margin: 0;
  padding: 0;
}

h3 {
  color: var(--Black, #151414);
  font-family: $font;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  padding: 0;

  @media (min-width: $scr-tablet) {
    font-size: 18px;
    line-height: 24px;
  }
}

h4 {
  @include txt-18;
  margin: 0;
  padding: 0;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

mt32 {
  margin-top: 32px;
}

.badge {
  display: inline-block;
  border-radius: 12px;
  padding: 4px 10px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  @include txt-12;
}

.ui-panel {
  border-radius: 8px;
  border: 1px solid var(--Porcelain, #ECF0F1);
  padding: 20px;
}

.ui-flex {
  display: flex;
  align-items: center;
  width: 100%;
  &.between {
    justify-content: space-between;
  }

  &.start {
    justify-content: flex-start;
  }
}



.ui-no-shrink {
  flex-shrink: 0;
}

.ui-no-wrap {
  white-space: nowrap;
}

.gap10 {
  gap: 10px;
}
