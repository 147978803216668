@use '@angular/material' as mat;
@use "./../oex-ui-kit/styles/controls/icons";
@import "@toast-ui/editor/dist/toastui-editor.css";
@import "./../oex-ui-kit/styles/main";
@import "fonts";
@import "breakpoint";
@import "theme";
@import "utils";
@import "markdown";
@import "ui";

/////////////////////// Angular material theme ///////////////////////////////////////////////
/*@include mat.core();
$app-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$indigo-palette),
    warn: mat.define-palette(mat.$indigo-palette),
    accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400)
  ),
  density: 0,
));

@include mat.all-component-themes($app-theme);*/
//////////////////////////////////////////////////////////////////////////////////////////////
@include mat.core();
$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));
@include mat.all-component-themes($my-theme);
/////////////////////////
@import "ng-select";

:root {
  --site-width: 1290px;
}

// Change colors of ui-kit images
.icon-admin-panel {
  @include icon;
  width: 20px;
  height: 20px;
  background-image: url("./assets/img/system-uicons_settings.svg");
}
.ui-icon-arrow-right {
  background-image: url("./assets/img/arrow-right-orange.svg");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

* {
    box-sizing: border-box;
}

iframe:not([src]):empty {
  display: none;
}

// Temp
//body {
//  overflow: hidden;
//}

html {
  position: relative;
  height: 100%;
}

body {
  overflow-x: hidden;
  // height: 100%;
  @include label;
  background-color: $bodyColor;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include breakpoint(737px 1169px) {
    overflow-x: auto;
  }

  @if $pd == 1 {
    //background-color: #f5f5f5;
    background-color: white;
  }
}

// Make space for footer
/*
body {
  margin-bottom: 133px; // 83 footer height  + 60 margin
}
*/

// Make all content cover full height
//app-root {
//    height: 100%;
//    & > div {
//        height: 100%;
//    }
//}

// Hide angular hidden element
[hidden] {
    display: none !important;
}

// Footer only for pd
.pd-footer {
  display: none;
  @if $pd == 1 {
    display: flex;
  }
  margin-top: 120px;
  @include breakpoint($scr-tablet) {
    margin-top: 100px;
  }
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: $c-blue-dark;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: white;

  @include breakpoint($scr-tablet) {
    padding-left: $tablet-gap;
    padding-right: $tablet-gap;
  }
  @include breakpoint($scr-mobile) {
    padding-left: $mobile-gap;
    padding-right: $mobile-gap;
  }

  & a {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }

  // Grid
  & > div {
    display: flex;
    //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    //gap: 130px;
    justify-content: space-between;
    width: $scr-width;
    padding-top: 60px;
    padding-bottom: 30px;
    border-bottom: 1px solid #888f95;

    @include breakpoint($scr-tablet) {
      padding-top: $tablet-gap;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      padding-bottom: 20px;
    }
    @include breakpoint($scr-mobile) {
      padding-top: $mobile-gap;
      grid-template-columns: 1fr 1fr;
    }

    // Item
    & a {
      display: block;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

// Different order of footer sections for mobile
@include breakpoint($scr-mobile) {
  .ft-fin {
    order: 0;
  }
  .ft-partner {
    order: 1;
  }
  .ft-iris-data {
    order: 2;
  }
  .ft-contact {
    order: 3;
  }
  .ft-help {
    order: 4;
  }
}

button {
  border: none;
  outline: none;
}

.mp-footer {
  // position: absolute;
  // bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
  @if $pd == 0 {
    margin-top: 120px;
    @include breakpoint($scr-tablet) {
      margin-top: 100px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;

  background: $c-blue-dark;
  padding-top: 30px;
  padding-bottom: 60px;
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: white;

  & > div {
    width: $scr-width;
    & > * {
      text-decoration: none;
      color: white;
      &:not(:last-child) {
        margin-right: 60px;
        @include breakpoint($scr-tablet) {
          margin-right: 20px;
        }
      }
    }

    & > a {
      white-space: nowrap;
      @include breakpoint($scr-mobile) {
        line-height: 30px;
      }
    }
  }

  @include breakpoint($scr-tablet) {
    padding-left: $tablet-gap;
    padding-right: $tablet-gap;
    padding-bottom: $tablet-gap ;
    & > div {
      width: 100%;
      word-break: break-word;
      & > span {
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  @include breakpoint($scr-mobile) {
    padding: $mobile-gap;
  }
}

.mp-app-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    width: 100%;
    // flex-wrap: wrap;
    @include breakpoint($scr-tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  @include breakpoint($scr-mobile) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.mp-list-view {
  width: 100%;
 /* & > tr:not(:first-child) {
    cursor: pointer;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: rgba(34, 37, 51, 0.12);
    }
  }*/
  & tr > th {
    padding: 20px 10px;
    @include txt-14;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid var(--Grey-1, #D1D5D4);
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  & tr > td {
    @include txt-12;
    padding-top: 24px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid var(--Grey-1, #D1D5D4);

    & > .app-name {
      display: flex;
      gap: 10px;
      max-width: 300px;
      & > div > h4 {
        display: block;
        @include txt-14;
        font-weight: 400;
        margin-bottom: 8px;
        margin-top: -4px;
        transition: color 0.2s linear;
      }
      & > div > p {
        @include txt-12;
        transition: color 0.2s linear;
      }
      &:hover > div > h4, &:hover > div > p {
        color: var(--Orange-Hover);
      }
      & > .app-logo {
        max-width: 30px;
        max-height: 30px;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        & > img {
          flex: 1 1 100%;
          max-width: 100%;
          max-height: 100%;
          width: 100%;
        }
      }
    }
    &.user {
      white-space: nowrap;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;

      & > a {
        display: flex;
        align-items: center;
        & > img {
          max-height: 24px;
          max-width: 100px;
          margin-right: 8px;
        }
      }
    }

    &.stats {
      & > img {
        vertical-align: middle;
        margin-right: 4px;
        transform: translateY(-1px);
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }

    & ui-avatar {
      margin-right: 8px;
      display: inline-flex;
      vertical-align: middle;
    }

    & .badge {
      transition: background-color 0.2s linear;
      display: inline-flex;
      flex-shrink: 0;
      flex-grow: 0;
      align-items: center;
      text-align: center;
      color: var(--Green, #00B2A9);
      border-radius: 4px;
      background: rgba(0, 178, 169, 0.08);
      padding: 0;
      height: 24px;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 24px;
    }
    & > .badges {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      max-width: 140px;
    }
  }
  // Hover state
  /*& > tr:hover > td {
    & .badge {
      background-color: white;
    }
  }*/
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

// Radio border
.mdc-radio__outer-circle {
  border-width: 1px !important;
}

// Checkbox style
.mdc-checkbox {
  --mdc-checkbox-selected-focus-icon-color: transparent;
}
.mdc-checkbox__background {
  border-width: 1px !important;
  box-sizing: border-box;
  border-radius: 2px;
}



// * Checkboxes inside dropdowns
.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: white;
}

// * Checkbox inside dropdown
.mat-pseudo-checkbox {
  border-radius: 2px;
  transition: border-color 0.2s linear;
  border: 1.5px solid var(--grey-1, #D1D5D4) !important;
  width: 18px !important;
  height: 18px !important;

  &.mat-pseudo-checkbox-checked {
    border: 1.5px solid var(--orange) !important;
  }

  // Checkmark
  &:after {
    background-image: url("/assets/img/checkbox-check.svg");
    width: 18px !important;
    height: 18px !important;
    left: -2px !important;
    top: -2px !important;
    transform: none !important;
    border: none !important;
  }
}

.mat-mdc-option:hover {
  & .mat-pseudo-checkbox {
    border: 1.5px solid var(--black) !important;
  }
}

// * Dropdown checkbox text
.mdc-list-item__primary-text, .mdc-list-item {
  font-family: $font;
  color: $c-black;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
}
// Selected item
.mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--selected .mdc-list-item {
  font-weight: bold !important;

}

.mdc-list-item {
  &.mdc-list-item--selected {
    --mat-option-selected-state-label-text-color: var(--Black);
  }
  & .mat-pseudo-checkbox {
    display: none !important;
  }
}

// * Dropdown checkboxes items line height
.mat-mdc-select-panel .mat-mdc-optgroup-label, .mat-mdc-select-panel .mat-mdc-option {
  height: 36px !important;
  min-height: 36px !important;
}

// * Checkbox without vertical align for check
.mat-mdc-checkbox.no-vert-align {
  & > .mdc-form-field {
    align-items: flex-start;
    & .mdc-checkbox {
      margin-top: 2px;
    }
  }
}
.mat-mdc-checkbox.no-vert-align2 {
  & > .mdc-form-field {
    align-items: flex-start;
    & .mdc-checkbox {
      margin-top: 4px;
    }
  }
}

  // Modal without restrictions
.modal-no-limits {
  max-width: none;
  min-width: auto;
  max-height: none;
  min-height: auto;
}

// ? Checkbox size fix
.mat-mdc-checkbox-inner-container {
  /*background-color: white;
  width: 20px !important;
  height: 20px !important;
  & > .mat-mdc-checkbox-frame {
    border: 1px solid $c-lightgray;
    border-radius: 2px;
  }*/
}

// * Hover state of checkbox
.mat-mdc-checkbox:hover {
 /* & .mdc-checkbox__background {
    background: rgba(230, 230, 230, 0.2) !important;
  }*/

 /* &.mat-mdc-checkbox-checked {
    & .mdc-checkbox__background {
      background: $c-main !important;
    }
  }*/
}

// * Hide checkbox circle
.mat-mdc-checkbox .mat-mdc-checkbox-ripple, .mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none !important;
}

// * Checkbox Label
.mat-mdc-checkbox label {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

// * Radio Label
mat-checkbox.reverse .mdc-form-field label, .mat-mdc-radio-button.reverse .mdc-form-field label {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

input {
  @include input-control;
}

textarea {
  @include input-control;
  height: auto;
}

// Link style
.mp-link {
  cursor: pointer;
  font-family: $font;
  font-size: 16px;
  line-height: 20px;
  text-align:left;
  color: $c-link;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: color 0.2s linear;

  & > a {
    color: $c-link;
    text-decoration: none;
    &:visited {
      text-decoration: none;
    }
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    color: $c-link-hover;
    & > a {
      color: $c-link-hover;
    }
  }

  &.secondary {
    color: $c-blue-dark;
    & > a {
      color: $c-blue-dark;
    }

    &:hover {
      color: $c-link-hover;
      & > a {
        color: $c-link-hover;
      }
    }
  }

  & > img {
    margin-right: 8px;
    // transition: filter 0.2s linear;
  }

  &:hover {
    & > img {
      filter: var(--flt-orange-hover);
    }
  }
}

// * Override matTootlip styles for better readability
.mat-mdc-tooltip {
  // box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);

  @include txt-12;
  border-radius: 4px;

  --mdc-plain-tooltip-container-color: var(--porcelain, #ECF0F1);
  --mdc-plain-tooltip-supporting-text-color: var(--black);
}

// Headline
//.mp-heading {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  font-family: $font;
//  font-size: 32px;
//  line-height: 37px;
//  font-weight: bold;
//  color: $c-black;
//  letter-spacing:0;
//
//  // Package image
//  & img {
//    height: 60px;
//    margin-right: 15px;
//    font-size: 12px;
//    font-weight: normal;
//    color: $c-black40;
//  }
//
//  // Small header
//  &.small {
//    font-size: 24px;
//  }
//}

// Markdown global style
.mp-markdown {
  word-break: break-word;
  & * {
     // font-family: $font;
  }
}
.mp-markdown, .toastui-editor-contents {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: $font;
  font-size: 14px;
  line-height: 20px;

  & img {
    max-width: 100%;
  }

  & pre {
    padding: 16px;
    overflow: auto;
    //font-size: 85%;
    font-size: 13px;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;

  }

  & p, & blockquote, & ul, & ol, & dl, & table, & pre {
    margin-top: 0;
    margin-bottom: 16px;
  }

  & h1 {
    padding-bottom: 0.3em;
    font-size: 20px;
    border-bottom: 1px solid #eaecef;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }
  & h2 {
    padding-bottom: 0.3em;
    font-size: 18px;
    border-bottom: 1px solid #eaecef;
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }
  & code, & tt {
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin: 0;
    //font-size: 85%;
    font-size: 13px;
    background-color: rgba(27,31,35,0.05);
    border-radius: 3px;
  }

  & pre code, & pre tt {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
    &::after, &::before {
      content: '';
    }
  }

  & li+li {
    margin-top: 0.25em;
  }
  &:not(.toastui-editor-contents) li>p {
    margin-top: 16px;
  }

  & ul, & ol {
    padding-left: 2em;
    list-style-type: disc;
  }
  & ol {
    list-style-type: decimal;

  }
  & a {
    display: inline-block;
    font-size: inherit;
    color: #0366d6;
    text-decoration: none;
  }

  & code::before, & code::after, & tt::before, & tt::after {
    letter-spacing: -0.2em;
    content: "\00a0";
  }

  & table {
    display: block;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;
  }
  & table th {
    font-weight: bold;
  }
  & table tr {
    border-top: 1px solid #c6cbd1;
  }
  & table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }
  & table th, & table td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

// * Fix material select
.mat-mdc-select-value {
  height: $input-height;
  padding: 0 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.mat-mdc-select-trigger {
  height: 100%;
}

// * Fix select arrow
.mat-mdc-select-arrow-wrapper {
  // width: 18px;
}
.mat-mdc-select-arrow {
  margin-right: 15px;
  width: 19px !important;
  height: 15px !important;
  background-repeat: no-repeat;
  background-image: url('/assets/img/arrow-down-select.svg');
  transition: transform 0.2s linear;
  & > svg {
    display: none;
  }
}
mat-select[aria-expanded=true] .mat-mdc-select-arrow {
  transform: scaleY(-1);
}

// Select text
.mat-mdc-select-value-text {
  font-size: 14px;
  color: var(--black);
}
// Select items
.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
  color: var(--black);
}

// * For sorting component - temporary workaround
[sorting] .mat-mdc-select-value {
  height: 20px;
  padding: 0;
  padding-right: 4px;
  padding-left: 10px;
}

// * Select style
.mat-mdc-select {
  @include input-control;
  border-radius: 8px;
  border: 1px solid var(--grey-1, #D1D5D4);
  background: var(--white, #FFF);
  padding: 0;
}

.fix-width-360 {
  width: 360px;
}

.fix-width-460 {
  width: 460px;
}


// Pill image for expanding
.img-pill {
  display: none;
  position: absolute;
  top: 6px;
  left: calc(50% - 14px);
  @include breakpoint($scr-mobile) {
    display: block;
  }
  border-radius: 8px;
  width: 28px;
  height: 4px;
  background-color: $c-lightgray;
}

// Class for hiding on mobile
.not-mobile {
  @include breakpoint($scr-mobile) {
    display: none !important;
  }
}

// Class to show only on mobile
.only-mobile {
  display: none !important;
  @include breakpoint($scr-mobile) {
    display: block !important;
  }
}

.only-tablet {
  display: none !important;
  @include breakpoint($scr-tablet) {
    display: block !important;
  }
  @include breakpoint($scr-mobile) {
    display: none !important;
  }
}

.non-tablet {
  @include breakpoint($scr-tablet) {
    display: none !important;
  }

  @include breakpoint($scr-mobile) {
    display: block !important;
  }
}

// Onscreen bottom button for mobile
.btn-mobile-bottom {
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2;

  @include breakpoint($scr-tablet) {
    display: flex;
  }

  position: fixed;
  left: 50%;
  margin-left: -110px;
  width: 220px;
  bottom: 30px;
  height: 40px;
  background: $c-lightgreen;
  box-shadow: 0 0 10px rgba(0, 178, 169, 0.5);
  font-size: 18px;
  line-height: 26px;
  font-family: $font;
  color: #fff;

  border-radius: 50px;
}

.btn-mobile-bottom__pd {
  background: $c-deepblue;
  box-shadow: 0 0 10px rgba(47, 42, 149, 0.5);
}

// Fullscreen modal for mobile
.modal-mobile-fullscreen {
  @include breakpoint($scr-mobile) {
    padding: 16px !important;
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0 !important;
    & input {
      width: 100% !important;
    }
    & .message {
      margin-bottom: 0 !important;
    }
    & .footer {
      flex-direction: column !important;
      & > .btn {
        height: 50px;
        margin-right: 0 !important;
        margin-right: 0 !important;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

// Expand button
.btn-expand {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 32px;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;

  @include breakpoint($scr-mobile) {
    display: block;
  }
}

//// Global style for row of news
//.news-row {
//  display: flex;
//  flex-direction: column;
//  position: relative;
//  border: 1px solid $c-lightgray;
//  box-sizing: border-box;
//  border-radius: 4px;
//  padding: 20px 40px;
//  margin-bottom: 30px;
//  font-family: $font;
//  font-size: 16px;
//  line-height: 24px;
//  color: $c-black;
//
//  // Sections of news
//  & > section {
//    width: 100%;
//  }
//
//  // Column title
//  & label {
//    display: block;
//    margin-bottom: 4px;
//    font-size: 14px;
//    line-height: 20px;
//    color: $c-black40;
//  }
//
//  // Blue line
//  &:before {
//    content: "";
//    width: 4px;
//    height: 100%;
//    display: block;
//    position: absolute;
//    left: 0;
//    top: 0;
//    bottom: 0;
//    background: $c-deepblue;
//    border-radius: 4px 0 0 4px;
//  }
//
//  @include breakpoint($scr-mobile) {
//    padding: 16px;
//  }
//}
//
//.opacity-04 {
//  opacity: 0.4;
//}

// Links in modal
.mp-modal > .message a {
  color: $c-link;
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }
}

// Checkbox text color fix
.mat-mdc-checkbox .mdc-form-field {
  color: var(--black);
}

// Radio label color fix
mat-checkbox.reverse .mdc-form-field label, .mat-mdc-radio-button.reverse .mdc-form-field label {
  color: var(--black);
}

.mdc-form-field>label {
  color: var(--black);
  cursor: inherit;
}

// ? Multiline material checkbox
mat-checkbox.multiline {
  & label {
    width: 100%;
    white-space: normal;
  }
}

// For 100% width
.width100 {
  width: 100% !important;
}

// For 100% width
.width100mobile {
  @include breakpoint($scr-mobile) {
    width: 100% !important;
  }
}

.overflow-visible {
  overflow-y: visible !important;
}

// Back button for preview
.btn-back-preview {
  cursor: pointer;
  font-family: $font;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  color: $c-deepblue;
  background-color: transparent;
  transform: translateY(-15px);
  padding: 8px;

  &:last-child {
    margin-top: 20px;
  }

  &:hover {
    // color: $c-black;
    /*& > img {
      opacity: 1;
    }*/
  }

  & > img {
    margin-right: 10px;
    height: 12px;
    opacity: 0.7;
  }
}

.multiline-tooltip {
  white-space: pre-line;
}

.small-cell {
  min-height: 50px;
  height: 50px;
  padding: 0 !important;
  vertical-align: middle;
}

.cell-ellipsis {
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;

  & > a {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

.small-cell-pad {
  min-height: 50px;
  height: 50px;
  padding: 8px !important;
  vertical-align: middle;
}

// Grids
.grid-2cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.span-2x {
  grid-column: span 2;
}

.flex-gap-24 {
  display: flex;
  gap: 24px;
}
//////////////////// Git syntax highlight ////////////////////////
:root {
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
}

.pl-k {
  color: var(--color-prettylights-syntax-keyword);
}

.pl-v, .pl-smw {
  color: var(--color-prettylights-syntax-variable);
}
.pl-s, .pl-pds, .pl-s .pl-pse .pl-s1, .pl-sr, .pl-sr .pl-cce, .pl-sr .pl-sre, .pl-sr .pl-sra {
  color: var(--color-prettylights-syntax-string);
}
.pl-e, .pl-en {
  color: var(--color-prettylights-syntax-entity);
}

.pl-c1, .pl-s .pl-v {
  color: var(--color-prettylights-syntax-constant);
}

.pl-c {
  color: var(--color-prettylights-syntax-comment);
}

///////////////////////////////////////////////////////////////////

// Apply font to all elements in portals
.screen-holder * {
  font-family: $font;
}

.overflow-visible {
  overflow: visible;
}

.nowrap {
  white-space: nowrap;
}

// Global card menu style, override material
.card-actions-menu {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08) !important;
  margin-top: 4px !important;
  // margin-right: -52px !important;
  &.with-gap {
    margin-top: 8px !important;
  }

  &.mat-menu-above {
    margin-bottom: 4px !important;

    &.with-gap {
      margin-bottom: 8px !important;
    }
  }
}
.mdc-list-item__primary-text > img {
  margin-right: 10px;
  // vertical-align: text-top;
}


//$col-main: if($pd, #2F2A95, #00b2a9);
$col-main: $c-primary;

:root {
  --mdc-theme-secondary: #{$c-main};
  --mdc-radio-selected-icon-color: #{$c-main};
  --mdc-radio-selected-hover-icon-color: #{$c-main};

  /*@if $pd == 1 {
    --mdc-theme-secondary: #{$c-darkblue};
    --mdc-radio-selected-icon-color: #{$c-darkblue};
    --mdc-radio-selected-hover-icon-color: #{$c-darkblue};
    --mdc-checkbox-selected-icon-color: #{$c-darkblue};
  }*/
}

// Border width
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control~.mdc-checkbox__background {
  border-width: 1.5px !important;
  transition: border-color 0.2s linear;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control+.mdc-radio__background .mdc-radio__outer-circle {
  border-width: 1.5px !important;
  transition: border-color 0.2s linear;
}


// Selected
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: $c-primary !important;
  // border-width: 1.5px !important;
}

// Hover bg
//.mat-mdc-checkbox:hover .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  background-color: white !important;
}
// Hover border
.mat-mdc-checkbox:hover .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background {
  border-color: $c-black !important;
}
.mat-mdc-radio-button:hover .mdc-radio .mdc-radio__native-control+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: $c-black !important;
}
// Global hover class - hover border
.global-hover:not(.disabled):hover .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background {
  border-color: $c-black !important;
}
.global-hover:not(.disabled):hover .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: $c-black !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #{$c-main};
  //--mdc-checkbox-selected-icon-color: #{$c-main};
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-focus-icon-color: #{$c-main};
  --mdc-checkbox-selected-hover-icon-color: #{$c-main};
  --mdc-checkbox-selected-pressed-icon-color: #{$c-main};

  --mdc-checkbox-unselected-icon-color: #{$c-lightgray};
  --mdc-checkbox-unselected-hover-icon-color: #{$c-lightgray};
  --mdc-checkbox-unselected-focus-icon-color: #{$c-lightgray};
  --mdc-checkbox-unselected-pressed-icon-color: #{$c-lightgray};

  --mdc-checkbox-state-layer-size: 18px;
}

// Change click zone
.mat-mdc-checkbox-touch-target, .mat-mdc-radio-touch-target {
  width: 20px !important;
  height: 20px !important;
}

mat-checkbox.w100, .mat-mdc-radio-button.w100 {
  width: 100%;
}

// Checkbox label
.mat-mdc-checkbox .mdc-form-field>label {
  padding-left: 8px;
}

// Reverse styles for checkbox/radio
mat-checkbox.reverse .mdc-form-field, .mat-mdc-radio-button.reverse .mdc-form-field {
  flex-direction: row-reverse;
  // justify-content: space-between;
  width: 100%;
  & label {
    width: 100%;
    cursor: pointer;
  }
}

// Checkbox bold label for checked
mat-checkbox.mat-mdc-checkbox-checked .mdc-form-field label, .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-form-field label {
  font-weight: bold;
}

// Change check image
.mdc-checkbox__mixedmark {
  background-image: url("/assets/img/checkbox-check.svg");
  transform: scale(0) !important;
  width: 20px !important;
  height: 20px !important;
  background-position: center;
  border: none !important;
}
mat-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__mixedmark {
  opacity: 1 !important;
  transform: scale(1) !important;
}

.mdc-checkbox__background > svg {
  display: none !important;
}

// Radio size
.mdc-radio__background {
  width: 18px !important;
  height: 18px !important;
}

// Remove radio ripple effect
.mat-mdc-radio-button .mat-radio-ripple {
  display: none !important;
}

// Remove blue radio background after clicking
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background: transparent !important;
}

.mdc-radio__inner-circle {
  border-width: 9px !important;
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 18px;

  //--mdc-radio-disabled-selected-icon-color: #000;
  //--mdc-radio-disabled-unselected-icon-color: #000;
  //--mdc-radio-unselected-focus-icon-color: #212121;
  //--mdc-radio-unselected-hover-icon-color: #212121;
  //--mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  //--mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);

  --mdc-radio-selected-focus-icon-color: #{$c-main};
  --mdc-radio-selected-hover-icon-color: #{$c-main};
  --mdc-radio-selected-icon-color: #{$c-main};
  --mdc-radio-selected-pressed-icon-color: #{$c-main};

  --mdc-radio-unselected-focus-icon-color: #{$c-lightgray};
  --mdc-radio-unselected-hover-icon-color: #{$c-lightgray};
  --mdc-radio-unselected-icon-color: #{$c-lightgray};
  --mdc-radio-unselected-pressed-icon-color: #{$c-lightgray};
}

// Progress bar
.mat-mdc-progress-bar {
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-active-indicator-color: var(--neon-blue);
}

// Change icons to green for OEX
@if $pd == 0 {
  .ui-icon-close-circle {
    @include icon;
    width: 24px;
    height: 24px;
    background-image: url('/assets/img/icon-circle-close.svg');
  }

  .ui-icon-close-circle-filled {
    @include icon;
    width: 24px;
    height: 24px;
    background-image: url('/assets/img/icon-circle-close-filled.svg');
  }

  // Change grid sorting icons color to green
  :root {
    --ui-grid-sort-icon-filter: var(--flt-green);
  }
}

@if $pd == 0 {
  input[type="date"] {
    &::-webkit-calendar-picker-indicator {
      background-image: url('/assets/img/icon-calendar-green.svg');
    }
  }
}

@import "portal";


