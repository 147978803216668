@import "variables";
@import "theme";
@import "txt";

@mixin ui-button {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  padding: 0 26px;
  border-radius: 4px;
  transition: background-color 0.2s linear;
  transition-property: background-color, background, color;
  height: 40px;

  /* Body 16 bold */
  text-align: center;
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  // Disabled state
  &[disabled], &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  // Fake disabled, clicks still working
  &[fake-disabled], &.fake-disabled {
    opacity: 0.5;
    cursor: default;
  }

  // Image at right
  & > img:last-child {
    margin-left: 10px;
  }

  // Sliding arrow image animation
  & > img.anim-slide-right {
    will-change: transform;
    transition: transform 0.2s linear;
  }

  // Sliding arrow image animation - hover
  &:hover > img.anim-slide-right {
    transform: translateX(5px);
  }
}

// Primary button style
.btn-primary {
  @include ui-button;
  background: var(--orange, #C75000);
  color: var(--white, #FFF);

  // Hover state
  &:hover {
    background: var(--orange-hover, #AE4600);
  }

  // Active state
  &:active {
    background: var(--orange-hover, #AE4600);
  }
}

// Primary button style
.btn-outline {
  @include ui-button;
  background: rgba(255, 255, 255, 0);
  color: var(--orange, #C75000);
  border: 1.2px solid var(--orange, #C75000);

  // Hover state
  &:hover {
    background: rgba(199, 80, 0, 0.08);
  }

  // Active state
  &:active {
    background: rgba(199, 80, 0, 0.08);
  }

  @if $pd == 0 {
    // Hover state
    &:hover {
      background: rgba(0, 199, 179, 0.08);
    }

    // Active state
    &:active {
      background: rgba(0, 199, 179, 0.08);
    }
  }
}

.btn-gray {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--blue-dark, #39444E);
  text-align: center;
  /* Body 14 regular */
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  background: var(--off-white, #F8F9F9);
  transition: background-color 0.2s linear;
  transition-property: background-color, background, color;
  cursor: pointer;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;

  & > span {
    color: var(--blue-dark, #39444E);
    text-align: center;
    font-size: 14px;
    font-family: $font;
    line-height: 20px;
    display: inline-block;
    width: 100%;

    &:empty {
      display: none;
    }
  }

  & > img {
    transition: filter 0.2s linear;
  }

  & > img + span:not(:empty) {
    margin-left: 10px;
  }
  & > span:not(:empty) + img {
    margin-right: 10px;
  }

  &:hover {
    color: var(--black, #151414);
    background: var(--porcelain, #ECF0F1);
    & > span {
      color: var(--black, #151414);
    }
    & > img {
      filter: var(--flt-black);
    }
  }
}

.btn-text {
  @include txt-16;
  font-weight: bold;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  transition: color 0.2s linear;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  & > img {
    transition: transform 0.2s linear;
    margin-left: 10px;
  }

  &:hover {
    color: var(--orange, #C75000);

    & > img {
      transform: translateX(5px);
    }
  }

  &:active, &:focus {
    outline: none;
  }
}

  h2 {
    color: var(--black, #151414);
    font-family: $font;
    font-size: 39px;
    font-style: normal;
    font-weight: 300;
    line-height: 53px;
  }

  h3 {
    color: var(--black, #151414);
    font-family: $font;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    @include breakpoint($scr-tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

h4 {
  color: var(--black, #151414);
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.info-tip {
  cursor: help;
  margin-left: 6px;
  transform: translateY(3px);
}

.btn-delete-input {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  transition: filter 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  & > img {
    height: 16px;
    margin-right: 0;
  }

  &:hover {
    & > img {
      filter: var(--flt-black);
    }
  }
}

.bottom-gap-l {
  margin-bottom: 48px;
  @include breakpoint($scr-tablet) {
    margin-bottom: 40px;
  }
}
