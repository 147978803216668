$hover-time: 0.2s;
$font: Oxygen, serif;

// Breakpoints
$scr-tablet: 1169px;
$scr-mobile: 736px;

// Screen gaps
$tablet-gap: 54px;
$mobile-gap: 20px;

// Scrollbar style
@mixin scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 24px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: var(--Border-Gray);
    border-left: 20px solid white;
  }
}

// Scrollbar shown only on hover
@mixin scrollbar-hover {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 24px;
    height: 32px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-radius: 0;
    background-color: white;
    border-left: 20px solid white;

  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 0;
    background-color: white;
    border-top: 10px solid white;
    border-bottom: 10px solid white;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--Grey-1);
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
    width: 1px;
    height: 1px;
  }
}

@mixin tablet-and-mobile-screen {
  @include breakpoint($scr-tablet) {
    padding-left: 54px;
    padding-right: 54px;
  }
  @include breakpoint($scr-mobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

