@import "variables";
@import "txt";
@import "theme";

.portal-breadcrumbs {
  margin-top: 10px;
  margin-bottom: 24px;
  @include txt-13;
  font-weight: 700;
  & > a {
    cursor: pointer;
    transition: color 0.2s linear;
    color: var(--grey-3, #909090);
    text-decoration: none;

    &:hover {
      color: var(--orange-hover);
    }
  }

  & > *:not(:last-child):after {
    content: '/';
    color: var(--grey-3, #909090);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.portal-header-tools {
  display: flex;
  margin-bottom: 48px;

  & > mat-select {
    width: 200px;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 0;
  }
  & > mat-select:last-child {
    margin-left: auto;
  }

  @include breakpoint($scr-tablet) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
    & > mat-select {
      width: 100%;
      margin-bottom: 20px;
    }
    & > mat-select:last-child {
      margin-left: initial;
      margin-bottom: 0;
    }
  }
}

.portal-info-card {
  display: flex;
  @include txt-12;
  padding: 20px;
  border: 1px solid var(--porcelain, #ECF0F1);
  gap: 6px;
  align-items: flex-start;
  justify-content: flex-start;
  & > img {

  }
}
