@import "variables";

label:has(input[type="checkbox"]) {
  cursor: pointer;
  user-select: none;
  font-weight: normal;
}

input[type="checkbox"] {
  position: relative;
  width: 18px;
  max-width: 18px;
  height: 18px;
  color: var(--Black);
  border: 1.5px solid var(--Grey-1);
  border-radius: 2px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  padding: 0;
  transition-property: border-color;
  transition-duration: $hover-time;
  transition-timing-function: linear;
  will-change: border-color;
  flex-shrink: 0;
  flex-grow: 0;
  &::before {
    transition: opacity $hover-time linear;
    position: absolute;
    content: '';
    display: block;
    top: 0px;
    left: 5px;
    width: 4px;
    height: 10px;
    border-style: solid;
    border-color: var(--Orange);;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:focus {
    border: 1.5px solid var(--Grey-1);
  }

  &:checked {
    border: 1.5px solid var(--Orange);
    &:focus {
      border: 1.5px solid var(--Orange);
    }
    &::before {
      opacity: 1;
    }
  }

  &:hover {
    border-color: var(--Black);
  }

  & + label {
    user-select: none;
    transform: translateY(1px);
    cursor: pointer;
  }
}
